<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
              ref="previewEl"
              rounded
              :src="item.img"
              height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
        >
          Изменить
        </b-button>
        <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="setImage"
        />
        <!--/ upload button -->

        <!-- reset -->
        <!--        <b-button-->
        <!--            v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
        <!--            variant="outline-secondary"-->
        <!--            size="sm"-->
        <!--            class="mb-75 mr-75"-->
        <!--        >-->
        <!--          Сброс-->
        <!--        </b-button>-->
        <!--/ reset -->
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer
        ref="updateUserData"
        #default="{invalid}"
    >
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group
              label="Username"
              label-for="account-username"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  v-model="item.name"
                  placeholder="Username"
                  name="username"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
                label="E-mail"
                label-for="account-e-mail"
            >
              <b-form-input
                  v-model="item.email"
                  disabled
                  name="email"
                  placeholder="Email"
              />

            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group
              label="Telegram"
              label-for="account-telegram"
            >
              <validation-provider
                #default="{ errors }"
                name="telegram"
                rules="required"
              >
                <b-form-input
                  v-model="item.telegram"
                  name="telegram"
                  placeholder="Telegram name"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- alert -->
          <b-col
              cols="12"
              class="mt-75"
          >
            <b-alert
                v-model="alertError"
                variant="danger"
            >
              <div class="alert-body">
                <span>{{ textError }}</span>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->

          <b-col cols="12">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click="save"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      optionsLocal: {},
      profileFile: [],
      alertError: false,
      textError: '',
    }
  },
  computed: {
    ...mapState({
      item: state => state.user.user,
    }),
  },
  mounted() {
    this.getItem()
  },
  methods: {
    ...mapActions({
      getItem: 'user/getUser',
      update: 'user/update',
    }),
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$refs.updateUserData.validate().then(success => {
        if (success) {
          this.update(this.profileFile).then(() => {
            this.$bvToast.toast('Данные успешно обновлены', {
              title: 'Сделка',
              variant: 'success',
              solid: true,
            })
          })
            .catch((errors) => {
              let textError = ''

              if (errors.response.status === 422) {
                textError = 'Проверьте введенные данные'

                this.$refs.updateUserData.setErrors(errors.response.data.errors)
              } else {
                textError = errors.response.data
              }

              this.$bvToast.toast(textError, {
                title: 'Ошибка',
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    },
    setImage() {
      const file = this.profileFile
      const reader = new FileReader()

      reader.addEventListener(
          'load',
          () => {
            this.item.img = reader.result
          },
          false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  },
}
</script>
